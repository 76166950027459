import {
    newTracker,
    trackPageView,
    enableActivityTracking,
    enableAnonymousTracking,
    disableAnonymousTracking,
    BrowserPlugin,
    TrackerConfiguration,
} from '@snowplow/browser-tracker';
import {
    LinkClickTrackingPlugin,
    enableLinkClickTracking,
} from '@snowplow/browser-plugin-link-click-tracking';
import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing';
import type { SelfDescribingJson } from '@snowplow/tracker-core';
import { GaCookiesPlugin } from '@snowplow/browser-plugin-ga-cookies';
import { getTrackerEndpoint } from './config';

const getTrackerAnonymousSettings = (
    hasUserAcceptedAgreement: boolean,
):
    | { withSessionTracking: boolean; withServerAnonymisation: boolean }
    | boolean => {
    if (!hasUserAcceptedAgreement) {
        return {
            withSessionTracking: true,
            withServerAnonymisation: true,
        };
    }

    return false;
};
type InitializationArguments = {
    hasUserAcceptedAgreement: boolean;
    plugins?: BrowserPlugin[];
    applicationId: string;
    snowplowContexts?: Record<string, boolean>;
    pageViewContexts?: SelfDescribingJson[];
    linkClickContexts?: SelfDescribingJson[];
    isProd?: boolean;
    collectorEndpoint?: string;
    configuration?: TrackerConfiguration;
    initialTrackPageViewEvent?: boolean;
};

let APP_ID: string = '';
let isSnowplowInitialised = false;

export const initSnowplowTracker = ({
    hasUserAcceptedAgreement = false,
    plugins = [],
    applicationId,
    snowplowContexts = {},
    pageViewContexts = [],
    linkClickContexts = [],
    isProd = false,
    collectorEndpoint = '',
    configuration = {},
    initialTrackPageViewEvent = true,
}: InitializationArguments) => {
    APP_ID = applicationId;

    newTracker(
        `sp-${applicationId}`,
        getTrackerEndpoint({ isProd, collectorEndpoint }),
        {
            appId: applicationId,
            platform: 'web',
            discoverRootDomain: true,
            encodeBase64: true,
            respectDoNotTrack: false,
            eventMethod: 'post',
            bufferSize: 1,
            maxPostBytes: 40000,
            stateStorageStrategy: 'localStorage',
            maxLocalStorageQueueSize: 1000,
            resetActivityTrackingOnPageView: true,
            connectionTimeout: 5000,
            cookieSameSite: 'Lax',
            cookieSecure: true,
            anonymousTracking: getTrackerAnonymousSettings(
                hasUserAcceptedAgreement,
            ),
            plugins: [
                LinkClickTrackingPlugin(),
                GaCookiesPlugin(),
                PerformanceTimingPlugin(),
                ...plugins,
            ],
            contexts: {
                webPage: true,
                ...snowplowContexts,
            },
            ...configuration,
        },
    );

    // Turn on page pings every 10 seconds
    enableActivityTracking({
        minimumVisitLength: 10,
        heartbeatDelay: 10,
    });
    // Track pageView should be fired after the enableActivityTracking
    if (initialTrackPageViewEvent) {
        trackPageView({
            context: pageViewContexts,
        });
    }

    enableLinkClickTracking({
        context: linkClickContexts,
    });

    isSnowplowInitialised = true;
};

export const enableAnonymizedTracking = () => {
    enableAnonymousTracking({
        options: getTrackerAnonymousSettings(false),
    });
};

export const disableAnonymizedTracking = () => {
    disableAnonymousTracking();
};

export const getSnowplowAppId = (): string => APP_ID;

export const hasSnowplowInitialised = () => {
    return isSnowplowInitialised;
};

export const getTrackerEndpoint = ({
    isProd,
    collectorEndpoint,
}: {
    isProd: boolean;
    collectorEndpoint: string;
}): string => {
    if (collectorEndpoint) {
        return collectorEndpoint;
    }

    if (isProd) {
        return 't.engelvoelkers.com';
    }

    return 'com-engelvoelkers-prod1.mini.snplow.net';
};

import type { SelfDescribingJson } from '@snowplow/tracker-core';
import { trackPageView } from '@snowplow/browser-tracker';

import * as contextTypes from './contextTypes';
import * as trackerTypes from './trackerTypes';
import * as init from './init';
import * as trackers from './trackers';
import * as contexts from './contexts';

export * from './contextTypes';
export * from './trackerTypes';
export * from './init';
export * from './trackers';
export * from './contexts';

export { trackPageView };
export type { SelfDescribingJson };

declare global {
    interface Window {
        evSnowplow: any;
    }
}

if (typeof window !== 'undefined') {
    window.evSnowplow = {
        ...contextTypes,
        ...trackerTypes,
        ...init,
        ...trackers,
        ...contexts,
    };
}
